import React, {
  ReactElement,
  PropsWithChildren,
  ElementType,
  Ref,
  forwardRef,
} from "react";
import {
  BaseProps,
  EventProps,
  MarginProps,
  PaddingProps,
  TextProps,
} from "../../types";
import { buildStyle } from "../../utils";
import "./Heading.scss";

type Props = BaseProps &
  MarginProps &
  PaddingProps &
  TextProps &
  EventProps<HTMLHeadingElement> & {
    variant?: HeadingVariant;
  };

type HeadingVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subheader";
type ComponentProps = PropsWithChildren<Props>;
type ComponentElement = ReactElement<ComponentProps>;

const HEADERS_COMPONENTS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subheader: 'p'
}

function Heading({
  variant = "h1",
  className,
  children,
  ...other
}: ComponentProps, ref: Ref<HTMLHeadingElement>): ComponentElement {
  const HeadingTag = HEADERS_COMPONENTS[variant] as ElementType;

  const style = buildStyle({ ...other });

  return (
    <HeadingTag className={`heading ${className || ''}`} style={style} ref={ref} {...other}>
      {children}
    </HeadingTag>
  );
}

/**
 * Heading is used to create various levels of typographic hierarchies
 */
 const _Component = forwardRef(Heading);
 export {_Component as Heading};