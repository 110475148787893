import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
  WheelEvent,
} from 'react';

import { log, Server } from 'common/space';
import { Box, Cols, Flex, Rows, ScrollBox } from 'ui/layout';
import { Course as CourseType } from 'common/types';
import { Link, Heading } from 'ui/atoms';

import { Logo } from 'student/components/Logo';

import './Course.scss';
import { CourseHeader } from 'student/components/CourseComponents/CourseHeader';
import { HeaderState } from 'student/components/CourseComponents/CourseHeader/CourseHeader';
import { CourseHeaderPure } from 'student/components/CourseComponents/CourseHeaderPure';
import CourseList from 'student/components/CourseComponents/CourseList/CourseList';
import { CourseNavMenu } from 'student/components/CourseComponents/CourseNavMenu';
import { ChevronLeft } from 'ui/icons';

type Props = {
  courseId: string;
  toREMOVE_isPureHeader?: boolean;
};

export default function Course({
  courseId,
  toREMOVE_isPureHeader,
}: PropsWithChildren<Props>): ReactElement<PropsWithChildren<Props>> {
  const [course, setCourse] = useState<CourseType | null>(null);
  const [headerState, setHeaderState] = useState<HeaderState>('start');

  useEffect(() => {
    Server.get(`/lessonplans/${courseId}.json`).then((res) => {
      if (res.error)
        return log(`Server error on getting course data: ${res.message}`);
      setCourse(res.data);
    });
  }, [courseId]);
  // overflowX: "hidden"
  const subTitle =
    course?.subject.name && course?.starting_grade
      ? `${course?.subject.name} | Level ${course?.starting_grade}`
      : '';

  function onScrollHandler(e: WheelEvent<HTMLDivElement>) {
    const position = (e.target as HTMLDivElement)?.scrollTop || 0;
    if (position > 5 && headerState !== 'minimized') {
      setHeaderState('minimized');
    } else if (position < 5 && headerState === 'minimized') {
      setHeaderState('maximized');
    }
  }

  return (
    <Box height='auto'>
      <Cols>
        <Box width='226px' height='auto' pt={15} my={0}>
          <Box id='side-bar'>
            <div className='logo-wrapper'>
              <Logo />
            </div>
            <Flex cols height='max-content' pl={32} pt={40}>
              <Link to='/'>
                <ChevronLeft className='chevron-left-icon' />
                <Heading variant='h6' fontWeight={600}>
                  Dashboard
                </Heading>
              </Link>
            </Flex>
            <CourseNavMenu />
          </Box>
        </Box>
        <Rows
          height='100vh'
          className={course ? 'animation visible' : 'animation hidden'}
        >
          {toREMOVE_isPureHeader ? (
            <>
              <CourseHeaderPure
                title={course?.name}
                subTitle={subTitle}
                height='156px'
              />
              <div className='course-container'>
                <CourseList lessons={course?.lessons} />
              </div>
            </>
          ) : (
            <>
              {' '}
              <CourseHeader
                title={course?.name}
                subTitle={`${course?.subject.name} | Level ${course?.starting_grade}`}
                state={headerState}
              />
              <ScrollBox
                height='1fr'
                id='scroll-box'
                onScroll={onScrollHandler}
              >
                <div className='course-container'>
                  <CourseList lessons={course?.lessons} />
                </div>
              </ScrollBox>
            </>
          )}
        </Rows>
      </Cols>
    </Box>
  );
}
