export const Handouts: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    className={`icon ${className}`}
  >
    <defs>
      <style>{`.handouts-cls-1{fill:none;}.handouts-cls-2,.handouts-cls-4{fill:currentColor;}.cls-3{fill:#fff;}.handouts-cls-4{isolation:isolate;opacity:0.999;}`}</style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <rect className='handouts-cls-1' width='16' height='16' />
        <path
          className='handouts-cls-2'
          d='M14.7,1.7a1.519,1.519,0,0,0-.18-.33,1.683,1.683,0,0,0-.24-.29,2.172,2.172,0,0,0-.29-.24,1.888,1.888,0,0,0-1.1-.34H5.756a1.934,1.934,0,0,0-1.1.34h-.01a3.482,3.482,0,0,0-.28.24,1.816,1.816,0,0,0-.43.62,2,2,0,0,0-.16.77v1.67h1V2.465a.973.973,0,0,1,.98-.97h7.13a.965.965,0,0,1,.97.97V10.9a.965.965,0,0,1-.97.97h-1.67v1h1.67a2,2,0,0,0,.77-.16,2.685,2.685,0,0,0,.33-.18,2,2,0,0,0,.53-.53,2.685,2.685,0,0,0,.18-.33,2,2,0,0,0,.16-.77V2.465A2,2,0,0,0,14.7,1.7Z'
        />
        <path
          className='cls-3'
          d='M12.056,4.335a1.519,1.519,0,0,0-.18-.33,1.683,1.683,0,0,0-.24-.29,2.172,2.172,0,0,0-.29-.24,1.955,1.955,0,0,0-1.1-.33H3.116a1.979,1.979,0,0,0-1.1.33h-.01a3.482,3.482,0,0,0-.28.24,1.816,1.816,0,0,0-.43.62,1.994,1.994,0,0,0-.15.77v8.43a1.994,1.994,0,0,0,.15.77,1.816,1.816,0,0,0,.43.62,1.958,1.958,0,0,0,1.39.58h7.13a1.924,1.924,0,0,0,1.39-.58,1.683,1.683,0,0,0,.24-.29,1.74,1.74,0,0,0,.18-.33,1.994,1.994,0,0,0,.15-.77V5.105A1.994,1.994,0,0,0,12.056,4.335Zm-.84,9.2a.965.965,0,0,1-.97.97H3.116a.973.973,0,0,1-.98-.97V5.105a.973.973,0,0,1,.98-.97h7.13a.965.965,0,0,1,.97.97Z'
        />
        <path
          className='handouts-cls-2'
          d='M12.056,4.335a1.519,1.519,0,0,0-.18-.33,1.683,1.683,0,0,0-.24-.29,2.172,2.172,0,0,0-.29-.24,1.888,1.888,0,0,0-1.1-.34H3.116a1.934,1.934,0,0,0-1.1.34h-.01a3.482,3.482,0,0,0-.28.24,1.816,1.816,0,0,0-.43.62,2,2,0,0,0-.16.77v8.43a2,2,0,0,0,.16.77,1.816,1.816,0,0,0,.43.62,1.958,1.958,0,0,0,1.39.58h7.13a1.924,1.924,0,0,0,1.39-.58,1.683,1.683,0,0,0,.24-.29,1.74,1.74,0,0,0,.18-.33,2,2,0,0,0,.16-.77V5.105A2,2,0,0,0,12.056,4.335Zm-.84,9.2a.965.965,0,0,1-.97.97H3.116a.973.973,0,0,1-.98-.97V5.105a.973.973,0,0,1,.98-.97h7.13a.965.965,0,0,1,.97.97Z'
        />
        <path
          className='handouts-cls-4'
          d='M9.4,8.245a.5.5,0,0,1-.5.5H4.451a.479.479,0,0,1-.39-.19c-.01-.01-.01-.01-.01-.02a.471.471,0,0,1,0-.58l.01-.01a.488.488,0,0,1,.39-.2H8.9A.5.5,0,0,1,9.4,8.245Z'
        />
        <path
          className='handouts-cls-4'
          d='M9.4,10.4a.5.5,0,0,1-.5.5H4.451a.488.488,0,0,1-.39-.2l-.01-.01a.47.47,0,0,1,0-.58.01.01,0,0,1,.01-.01.488.488,0,0,1,.39-.2H8.9A.5.5,0,0,1,9.4,10.4Z'
        />
      </g>
    </g>
  </svg>
);
