import languageDetector from './languageDetector';
import { ApplicationLocales, ScriptDirection } from './types.d';

import data from './data.json';

const FALLBACK_CURRENCY = 'USD';

const get = (
  obj: Record<string, any> | any,
  path: string,
  defaultValue = undefined,
) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj,
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

/**
 * Represents locale instance
 */
export default class Locale {
  private _id: string;
  private _langNativeName: string;
  private _currency: string;
  private _langCode: keyof typeof data.languages;
  private _countryCode: keyof typeof data.countries;
  private _scriptDirection: ScriptDirection;

  constructor(
    langCode: keyof typeof data.languages,
    countryCode?: keyof typeof data.countries,
  ) {
    this._countryCode =
      countryCode || getCountryCodeFromLocale(data.primaryDialects[langCode]);
    this._id = `${langCode}${this._countryCode ? '_' + this._countryCode : ''}`;
    this._langCode = langCode;
    this._langNativeName = data.languages[langCode][0];
    this._scriptDirection = data.rtlScriptDirection.includes(langCode)
      ? 'rtl'
      : 'ltr';
    this._currency = data.countries[this._countryCode]?.[0];
  }

  /**
   * Locale id
   */
  get id(): string {
    return this._id;
  }

  /**
   * Locale alias (as in browser)
   */
  get alias(): string {
    return this._id.replace('_', '-');
  }

  /**
   * Locale language tag (for URL)
   */
  get tag(): string {
    return isPrimaryDialect(this) ? this._langCode : this.alias;
  }

  /**
   * Langauge native name
   */
  get langName(): string {
    return this._langNativeName;
  }

  /**
   * Locale view (presentation) name
   */
  get viewName(): string {
    let result = this._langNativeName;
    if (!isPrimaryDialect(this)) {
      if (this.countryName.length > 8) {
        result = `${result} (${this._countryCode})`;
      } else {
        result = `${result} (${this.countryName})`;
      }
    }
    return result;
  }

  /**
   * Language ISO code
   */
  get langCode(): keyof typeof data.languages {
    return this._langCode;
  }

  /**
   * Contry ISO code
   */
  get countryCode(): keyof typeof data.countries {
    return this._countryCode;
  }

  /**
   * Contry name
   */
  get countryName(): string {
    return data.countries[this._countryCode][1];
  }

  /**
   * Language script direction
   */
  get dir(): ScriptDirection {
    return this._scriptDirection;
  }

  /**
   * Converts money amount to the locale representation
   * @param amount - `number`
   * @returns `string`
   */
  priceSign(amount: number = 0): string {
    amount = Math.abs(amount / 100);
    return amount.toLocaleString(this.alias, {
      style: 'currency',
      currency: this._currency || FALLBACK_CURRENCY,
    });
  }
}

function isPrimaryDialect(locale: Locale): boolean {
  return data.primaryDialects[locale.langCode] === locale.id;
}

export function getLocaleCodes(localeId: string): {
  langCode: keyof typeof data.languages;
  countryCode: keyof typeof data.countries;
} {
  localeId =
    data.primaryDialects[localeId as keyof typeof data.languages] || localeId;
  return {
    langCode: getLangCodeFromLocale(localeId),
    countryCode: getCountryCodeFromLocale(localeId),
  };
}

export function getLangCodeFromLocale(
  localeId: string,
): keyof typeof data.languages {
  let result = localeId;
  const _position = localeId.indexOf('_');
  if (_position > 0) {
    result = localeId.slice(0, _position);
  }
  return result as keyof typeof data.languages;
}

export function getCountryCodeFromLocale(
  localeId: string,
): keyof typeof data.countries {
  let result;
  const _position = localeId.indexOf('_');
  if (_position > 0) {
    result = localeId.slice(_position + 1);
  }
  return result as keyof typeof data.countries;
}

export function getLocale(localeId: string = ''): Locale | undefined {
  if (typeof localeId !== 'string') {
    //TODO: maybe we need to log such cases?
    return undefined;
  }
  const list = getLocales([localeId.replace('-', '_')]);
  return list.length ? list[0] : undefined;
}

export function getLocales(
  localeIds: string[] | undefined = [],
): ApplicationLocales {
  const result: ApplicationLocales = [] as unknown as ApplicationLocales;
  for (let localeId of localeIds) {
    const localeCodes = getLocaleCodes(localeId);
    if (!data.languages[localeCodes.langCode]) {
      continue;
    }
    const locale = new Locale(localeCodes.langCode, localeCodes.countryCode);
    result.push(locale);
    result[locale.id] = locale;
  }
  return result;
}

export function getUserLocale(
  defaultLocale: string,
  user: any,
  userLangKeys: string[],
): Locale {
  let userLocaleId: string = '';

  const detected = languageDetector.detect();
  if (Array.isArray(detected)) {
    userLocaleId = detected[0];
  } else {
    userLocaleId = detected!;
  }

  let locale = getLocale(userLocaleId || defaultLocale || 'en_US');
  if (user) {
    for (let path of userLangKeys) {
      locale = getLocale(get(user, path)) || locale;
    }
  }

  return locale || getLocale(defaultLocale)!;
}

export function getCurrency(
  localeId: string,
  defaultCurrency?: string,
): string {
  return (
    data.countries[getCountryCodeFromLocale(localeId)][0] ||
    defaultCurrency ||
    'USD'
  );
}
