export const CourseInfo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    className={`icon ${className}`}
  >
    <defs>
      <style>{`.course-info-cls-1{fill:none;}.course-info-cls-2{fill:currentColor;}.course-info-cls-3{isolation:isolate;}`}</style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <rect className='course-info-cls-1' width='16' height='16' />
        <g id='Ellipse_76' data-name='Ellipse 76'>
          <path
            className='course-info-cls-2'
            d='M8,14.5A6.5,6.5,0,1,1,14.5,8,6.508,6.508,0,0,1,8,14.5ZM8,2.429A5.571,5.571,0,1,0,13.571,8,5.577,5.577,0,0,0,8,2.429Z'
          />
        </g>
        <g id='i' className='course-info-cls-3'>
          <g className='course-info-cls-3'>
            <path
              className='course-info-cls-2'
              d='M7.271,11.027A1.145,1.145,0,0,1,7,10.192a3.256,3.256,0,0,1,.074-.6L7.7,6.607H9.04L8.371,9.765a1.127,1.127,0,0,0-.037.3.341.341,0,0,0,.089.265.431.431,0,0,0,.292.079.749.749,0,0,0,.3-.055.985.985,0,0,1-.33.71,1.038,1.038,0,0,1-.691.245A.964.964,0,0,1,7.271,11.027ZM8.1,5.893a.712.712,0,0,1-.213-.521A.722.722,0,0,1,8.1,4.848a.7.7,0,0,1,.52-.219.746.746,0,0,1,.743.743.705.705,0,0,1-.218.521.725.725,0,0,1-.525.213A.709.709,0,0,1,8.1,5.893Z'
            />
          </g>
        </g>
        <rect className='course-info-cls-1' width='16' height='16' />
      </g>
    </g>
  </svg>
);
