export const CheckmarkCircle: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    className={`icon ${className}`}
  >
    <defs>
      <style>{`.checkmark-circle-cls-1{fill:currentColor;}.checkmark-circlecls-2{fill:#fff;}`}</style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <g id='Group_12467' data-name='Group 12467'>
          <g id='Ellipse_11-2' data-name='Ellipse 11-2'>
            <circle
              className='checkmark-circle-cls-1'
              cx='16'
              cy='16'
              r='14.125'
            />
          </g>
          <path
            className='checkmark-circlecls-2'
            d='M23.79,11.811a1.44,1.44,0,0,0-.818-.735,1.294,1.294,0,0,0-1.486.454l-7.028,7.539c-.942-1.021-1.885-2.041-2.815-3.044a2.325,2.325,0,0,0-.457-.417A1.366,1.366,0,0,0,9.345,16a1.53,1.53,0,0,0,.118,2l3.916,4.247a1.252,1.252,0,0,0,1,.489,1.192,1.192,0,0,0,.96-.463q4.079-4.389,8.16-8.746a1.49,1.49,0,0,0,.365-.579A1.474,1.474,0,0,0,23.79,11.811Zm-9.332,7.947ZM13.9,22.1Z'
          />
        </g>
        <path
          className='checkmark-circlecls-2'
          d='M16,1.883A14.117,14.117,0,1,1,1.883,16,14.118,14.118,0,0,1,16,1.883M16,0A16,16,0,1,0,32,16,16.019,16.019,0,0,0,16,0Z'
        />
      </g>
    </g>
  </svg>
);
