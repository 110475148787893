import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Space from "common/space";
import * as settings from "./settings.json";

import Course from "./pages/Course/Course";
import "./theme/index.scss";

export default function App(): ReactElement<PropsWithChildren<{}>> {
  useEffect(() => {
    (async () => {
      await Space.init(settings);
    })();
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path='/'
          element={<Course courseId='prog2' toREMOVE_isPureHeader={true} />}
        />
        <Route path='/2' element={<Course courseId='prog2' />} />
      </Routes>
    </Router>
  );
}
