import React, { PropsWithChildren, ReactElement } from "react";
import { Image } from "ui/atoms";
import { BaseProps, MarginProps, PaddingProps } from "ui/types";

type Props = BaseProps & PaddingProps & MarginProps & {};

export function Logo({
  ...other
}: PropsWithChildren<Props>): ReactElement<PropsWithChildren<Props>> {
  return <Image src='/images/logo.svg' width={130} height={40} {...other} />;
}
