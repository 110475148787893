import React, { CSSProperties, forwardRef, PropsWithChildren, ReactElement, Ref } from 'react';
import { Property } from 'csstype';
import { BaseProps, EventProps, MarginProps, PaddingProps } from '../../types';
import { buildStyle } from '../../utils';
import './Flex.scss';

type Props = BaseProps &
  MarginProps &
  PaddingProps &
  EventProps<HTMLDivElement> & {
    rows?: boolean;
    cols?: boolean;
    place?: [Property.AlignItems, Property.AlignContent] | [Property.AlignItems] | Property.AlignItems;
    gap?: string | number;
  };

type ComponentProps = PropsWithChildren<Props>;
type ComponentElement = ReactElement<ComponentProps>;

/**
 * Container for positioning child elements
 */
function Flex({
  rows,
  cols,
  place,
  className,
  children,
  ...other
}: ComponentProps, ref: Ref<HTMLDivElement>): ComponentElement {

  const style: CSSProperties = {
    ...buildStyle({ ...other })
  };

  if (Array.isArray(place) && place.length) {
    style.justifyContent = place[0];
    if (place.length > 1) {
      style.alignItems = place[1];
    }
  } else if (typeof place === 'string') {
    style.justifyContent = place;
    style.alignItems = place;
  }

  let flexClassName = rows ? 'rows' : 'cols';
  if (!rows && !cols) flexClassName = 'stack';

  return (
    <div className={`flex-${flexClassName} ${className || ''}`} style={style} ref={ref} {...other}>
      {children}
    </div>
  );
}

/**
 * Container for positioning child elements
 */
const _Component = forwardRef(Flex);
export {_Component as default};