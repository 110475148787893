import React, { ReactElement, PropsWithChildren, CSSProperties, Ref, forwardRef } from 'react';
import { buildStyle } from 'ui/utils';
import { getGridTemplate } from '../utils';
import { BaseProps, EventProps, MarginProps, PaddingProps } from '../../types';
import './Rows.scss';

type Props = 
  BaseProps &
  MarginProps &
  PaddingProps &
  EventProps<HTMLDivElement> & {
    gap?: string | number;
  };

type ComponentProps = PropsWithChildren<Props>;
type ComponentElement = ReactElement<ComponentProps>;

/**
 * An array of components arranged vertically
 */
function Rows({
  className,
  children,
  ...other
}: ComponentProps, ref: Ref<HTMLDivElement>): ComponentElement {

  const style: CSSProperties = {
    gridTemplateRows: getGridTemplate(children, 'height'),
    ...buildStyle({ ...other })
  };

  return (
    <div className={`rows ${className || ''}`} style={style} ref={ref} {...other}>
      {children}
    </div>
  );
}

/**
 * An array of components arranged vertically
 */
const _Component = forwardRef(Rows);
export {_Component as default};