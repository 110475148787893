import i18n, { TFunction } from 'i18next';
import Space from './space';

const instance: Space = new Space();

const t: TFunction = instance.t;
const Server = instance.Server;
const log = instance.log;

export default instance;
export { t, i18n, Server, log };
