import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'path'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'user.pref.language',
  lookupLocalStorage: 'user.pref.language',
  lookupSessionStorage: 'user.pref.language',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

const mockService = { languageUtils: { getBestMatchFromCodes: false } };

const languageDetector = new LanguageDetector(mockService, options);

export default languageDetector;
