export const Lock: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width='14'
    height='18'
    viewBox='0 0 14 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g clipPath='url(#clip0_144_2396)'>
      <path
        d='M12.7067 8.08973H10.5833C10.5717 8.03348 10.5833 6.77348 10.5717 6.35723C10.595 4.46723 9.11334 2.86973 7.16501 2.71223H6.83834C4.89001 2.86973 3.40834 4.46723 3.43167 6.35723C3.42001 6.77348 3.43167 8.03348 3.42001 8.08973H1.29667C1.28501 8.06723 1.27334 7.12223 1.29667 6.80723C1.28501 3.90473 3.24501 0.934727 6.39501 0.597227C6.59334 0.574727 6.80334 0.563477 7.00167 0.563477C7.20001 0.563477 7.41001 0.574727 7.60834 0.597227C10.7583 0.934727 12.7183 3.90473 12.7067 6.80723C12.73 7.12223 12.7183 8.06723 12.7067 8.08973Z'
      />
      <path
        d='M12.0856 7.7207H1.9181C0.9731 7.74095 0.2206 8.49695 0.2346 9.41383V15.7453C0.219434 16.6622 0.971933 17.4193 1.9181 17.4385H12.0844C13.0318 17.4216 13.7843 16.6633 13.7679 15.7453V9.41383C13.7831 8.49695 13.0318 7.73983 12.0856 7.7207V7.7207ZM7.00127 14.0871C6.14377 14.1163 5.4251 13.4661 5.39593 12.6347C5.3656 11.8033 6.03643 11.107 6.89393 11.0777C7.75143 11.0485 8.4701 11.6987 8.49927 12.5301V12.5796C8.51793 13.3952 7.84943 14.0702 7.00827 14.0871H7.00243H7.00127Z'
      />
    </g>
    <defs>
      <clipPath id='clip0_144_2396'>
        <rect width='14' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
