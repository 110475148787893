import { PropsWithChildren, ReactElement } from "react";
import { Flex } from "ui/layout";
import { BaseProps } from "ui/types";
import { Link } from "ui/atoms";
import "./CourseNavMenu.scss";
import { Outline, PlacementTest, Stats , Badges, Scoreboard, Handouts, CourseInfo} from "ui/icons";


type Props = BaseProps & {};

type MenuItem = {
  icon: string;
  to: string;
  caption: string;
  src?: string;
  component?: ReactElement;
};

const menuItems: MenuItem[] = [
  { icon: "Outline", to: "/", caption: "Outline", component: <Outline /> },
  {
    icon: "PlacementTest",
    to: "/",
    caption: "Placement test",
    component: <PlacementTest />,
  },
  { icon: "Stats", to: "/", caption: "Course stats", component: <Stats />},
  {
    icon: "Badges",
    to: "/",
    caption: "Course badges",
    component: <Badges />,
  },
  {
    icon: "Scoreboard",
    to: "/",
    caption: "Scoreboard",
    component: <Scoreboard />,
  },
  {
    icon: "Handouts",
    to: "/",
    caption: "Resources",
    component: <Handouts />,
  },
  {
    icon: "CourseInfo",
    to: "/",
    caption: "Course info",
    component: <CourseInfo />,
  },
];

export default function CourseNavMenu(): ReactElement<PropsWithChildren<Props>> {
  return (
    <Flex rows pl={32} gap={24} pt={32} className='course-nav-menu'>
      {menuItems.map((item: MenuItem, index: number) => {
        return (
          <Link to={item.to} className='course-nav-menu-item' key={index}>
            {item?.component}
            {item.caption}
          </Link>
        );
      })}
    </Flex>
  );
}
