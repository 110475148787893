type HTTPMethod = 'GET' | 'POST' | 'UPDATE' | 'DELETE' | 'PATCH';

export type ServerSettings = {};

export type ServerResponse = {
  error: boolean;
  data: any;
  message: string;
};

export default class Server {
  async init(settings: ServerSettings): Promise<void> {
    return Promise.resolve();
  }

  async get(url: string, headers?: any, body?: any): Promise<ServerResponse> {
    return make(url, 'GET', headers, body);
  }

  async post(url: string, headers?: any, body?: any): Promise<ServerResponse> {
    return make(url, 'POST', headers, body);
  }
}

async function make(
  url: string,
  method: HTTPMethod,
  headers?: any,
  body?: any,
): Promise<ServerResponse> {
  let error = false;
  let data = { url, method, headers, body };
  let message = '';

  headers = headers || {};
  headers['Content-Type'] = 'application/json';

  let response: any;
  try {
    response = await fetch(url, {
      method,
      headers,
      body,
    });
  } catch (err) {
    error = true;
    message = `Unsuccessful attempt to make a request`;
  }

  if (response.ok) {
    try {
      data = await response.json();
    } catch (err) {
      error = true;
      message = `Can't read a response body`;
    }
  } else {
    error = true;
    message = `Error on making a request`;
  }

  return { error, data, message };
}
