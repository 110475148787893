import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { Image, Badge, Heading } from 'ui/atoms';
import { BaseProps } from 'ui/types';

import './CourseHeaderPure.scss';
import { Flex } from 'ui/layout';
import { Caret } from 'ui/icons';

type Props = BaseProps & {
  title: string | ReactNode;
  subTitle: string | ReactNode;
};

export type HeaderState = 'start' | 'maximized' | 'minimized';

const ANIMATION_DELAY = 300;

const Menu: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className={`menu`}>
      <Flex cols gap={4}>
        {children}
      </Flex>
      <Caret />
    </div>
  );
};

export function CourseHeaderPure({
  title = '',
  subTitle = '',
  ...other
}: PropsWithChildren<Props>): ReactElement<PropsWithChildren<Props>> {
  const [initialView, setInitialView] = useState(true);

  const titleRef = useRef<HTMLHeadingElement | null>(null);
  const subtitleRef = useRef<HTMLHeadingElement | null>(null);
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const isAnimating = useRef(false);

  const { ref, inView } = useInView({
    threshold: 0.85,
  });

  const clearAnimation = () => {
    setTimeout(() => {
      isAnimating.current = false;
    }, ANIMATION_DELAY);
  };

  const animate = () => {
    if (titleRef.current && subtitleRef.current && navbarRef.current) {
      isAnimating.current = true;

      const subTitleElement = subtitleRef.current;
      subTitleElement.style.left = `0px`;
      // TODO: comment that if you need separated animation
      // navbarRef?.current?.classList.add("animated");

      navbarRef?.current?.classList.add('animate-text');

      setTimeout(() => {
        // TODO: comment that out if you need separated animation
        navbarRef?.current?.classList.add('animated');
        clearAnimation();
      }, ANIMATION_DELAY);
    }
  };

  const reverseAnimate = () => {
    if (titleRef.current && subtitleRef.current && navbarRef.current) {
      navbarRef?.current?.classList.remove('animated');
      navbarRef?.current?.classList.remove('animate-text');

      setTimeout(() => {
        const subTitleElement = subtitleRef.current;
        const titleElement = titleRef.current;
        const titleWidth = titleElement?.offsetWidth;
        if (subTitleElement && titleWidth) {
          subTitleElement.style.left = `-${titleWidth-20}px`;
        }
        clearAnimation();
      }, ANIMATION_DELAY);
    }
  };

  useEffect(() => {
    if (!initialView && isAnimating.current === false) {
      inView ? reverseAnimate() : animate();
    } else {
      setInitialView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useLayoutEffect(() => {
    const titleElement = document.getElementById('title');

    if (titleRef.current && subtitleRef.current) {
      const subTitleElement = subtitleRef.current;
      const titleWidth = titleElement?.offsetWidth;

      if (titleWidth) {
        subTitleElement.style.left = `-${titleWidth-20}px`;
      }
    }
  }, [title]);

  return (
    <div ref={navbarRef} className='navbar'>
      <div className='scroll-container' ref={ref} />
      <div className='course-navbar-container'>
        <div className='header-padding-container'>
          <div className='headers-container'>
            <div id='title' ref={titleRef} className='header-container'>
              <div className={`title-bar`} />
              <Heading className='main-title'>{title}</Heading>
            </div>
            <div className='subheader-container'>
              <Heading variant='h2' ref={subtitleRef}>
                {subTitle && `${subTitle}`}
              </Heading>
            </div>
          </div>
        </div>
        <div className='user-button-container'>
          <Menu>
            <Image src='/images/course_icons/language.svg' size={17} />
            English
          </Menu>
          <Menu>
            <Image src='/images/course_icons/avatar.png' className='avatar' size={32} />
            David M
          </Menu>
        </div>
        <div className='badges-container'>
          <Badge>
            <Heading fontWeight='bold' variant='h6'>
              76
            </Heading>
            <Heading variant='subheader' className='subheading'>
              Stars
            </Heading>
          </Badge>
          <Badge>
            <Heading fontWeight='bold' variant='h6'>
              20,865
            </Heading>
            <Heading variant='subheader' className='subheading'>
              Score
            </Heading>
          </Badge>
          <Badge>
            <Heading fontWeight='bold' variant='h6'>
              45%
            </Heading>
            <Heading variant='subheader' className='subheading'>
              Progress
            </Heading>
          </Badge>
        </div>
      </div>
    </div>
  );
}
