import React, { ReactElement, PropsWithChildren } from "react";
import {
  BaseProps,
  EventProps,
  MarginProps,
  PaddingProps,
  TextProps,
} from "../../types";
import { buildStyle } from "../../utils";
import "./Text.scss";

type Props = BaseProps &
  MarginProps &
  PaddingProps &
  TextProps &
  EventProps<HTMLSpanElement>;

type ComponentProps = PropsWithChildren<Props>;
type ComponentElement = ReactElement<ComponentProps>;

function Text({
  className,
  children,
  ...other
}: ComponentProps): ComponentElement {
  const style = buildStyle({ ...other });

  return (
    <span className={`text ${className || ""}`} style={style} {...other}>
      {children}
    </span>
  );
}

export { Text };
