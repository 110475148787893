import React from "react";

export const Badges: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={`icon ${className}`}
    viewBox='0 0 16 16'
  >
    <path
      className='cls-1'
      d='M8,12.372a5.936,5.936,0,1,1,5.936-5.936A5.943,5.943,0,0,1,8,12.372ZM8,1.5a4.936,4.936,0,1,0,4.936,4.936A4.941,4.941,0,0,0,8,1.5Z'
    />
    <polygon
      className='cls-1'
      points='11.135 15.804 8.095 14.305 4.865 15.777 4.865 11.344 5.865 11.344 5.865 14.223 8.112 13.198 10.135 14.196 10.135 11.344 11.135 11.344 11.135 15.804'
    />
    <path
      className='cls-1'
      d='M8,9.654a3.218,3.218,0,1,1,3.218-3.218A3.222,3.222,0,0,1,8,9.654ZM8,4.218a2.218,2.218,0,1,0,2.218,2.218A2.221,2.221,0,0,0,8,4.218Z'
    />
  </svg>
);
