import React, { PropsWithChildren, ReactElement } from 'react';
import { Flex } from 'ui/layout';
import { BaseProps, MarginProps, PaddingProps } from '../../types';
import './Badge.scss';

type Props = BaseProps & PaddingProps & MarginProps;

export function Badge({ children }: PropsWithChildren<Props>): ReactElement<PropsWithChildren<Props>> {
  return (
    <Flex cols gap={4} className={`badge`}>
      {children}
    </Flex>
  );
}
