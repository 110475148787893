import i18n, { TFunction, TFunctionResult } from 'i18next';
import Backend from 'i18next-http-backend';

import Locale, { getLocale, getLocales, getUserLocale } from './locales/index';

import { SpaceSettings } from './types.d';
import { ApplicationLocales } from './locales/types';
import Server from './server';
import log from './logger';

let instance: Space;

/**
 * The application environment object storing high level state values
 * available from all the points of the application and giving widely
 * used methods. Uniq for the whole application
 */
export default class Space {
  //@ts-ignore - we will assign them on init
  public settings: SpaceSettings = {};
  public defaultCurrency: string = 'USD';
  public staticURL: string = '';
  public spaceLocales: ApplicationLocales;
  public contentLocales: ApplicationLocales;

  //@ts-ignore - we will assign them on init
  private _defaultLocale: Locale;
  private _context: any;
  //@ts-ignore - we will assign them on init
  private _t: TFunction;

  private _server: Server;

  constructor() {
    this.spaceLocales = [] as unknown as ApplicationLocales;
    this.contentLocales = [] as unknown as ApplicationLocales;
    this._context = {};
    this.t = this.t.bind(this);

    this._server = new Server();

    // we need only one instance of a Space in our application
    if (instance) {
      return instance;
    }
  }

  /**
   * Initializes the Space instance
   *
   * @param settings -`SpaceSettings` object
   * @returns `Space` instance
   */
  async init(settings: SpaceSettings): Promise<Space> {
    this.settings = settings;
    this.staticURL = settings.staticURL.replace(/\/$/, ''); // if the URL was passed with '/' at the end

    this.defaultCurrency =
      settings.localization.defaultCurrency || this.defaultCurrency;
    this.spaceLocales = getLocales(settings.localization.spaceLocales);
    this.contentLocales = getLocales(settings.localization.contentLocales);

    this._defaultLocale = getLocale(
      settings.localization.defaultLocale || 'en_US',
    )!;
    const userLocale = this.getUserLocale();

    this._t = await i18n.use(Backend).init({
      lng: userLocale.id,
      fallbackLng: [this._defaultLocale.id],
      load: 'currentOnly',
      ns: settings.localization.namespaces,
      fallbackNS: settings.localization.namespaces,
      backend: {
        loadPath: `${window.location.origin}/${this.staticURL}/locales/{{lng}}/{{ns}}.json`,
        crossDomain: true,
      },
      debug: false,
      nsSeparator: '::',
      keySeparator: '||',
    });

    await this._server.init({});

    return this;
  }

  /**
   * Gets current user locale instance
   *
   * @param user - any object storing locale id
   * @returns `Locale` instance
   */
  getUserLocale(user?: any): Locale {
    let locale = getUserLocale(
      this.settings.localization.defaultLocale || 'en_US',
      user,
      this.settings.localization.userLocaleKeys || [
        'account.language',
        'language',
      ],
    );
    if (!this.spaceLocales[locale.id]) {
      // we do not service that locale yet
      locale = this._defaultLocale;
    }
    return locale;
  }

  /**
   * Gets locale by id
   *
   * @param localeId - `string` locale id. Both options are accepted: with '-' and '_'
   * @returns `Locale` instance | undefined
   */
  getLocale(localeId: string | undefined): Locale | undefined {
    if (!localeId) {
      return undefined;
    }
    return getLocale(localeId);
  }

  /**
   * Gets application default locale
   */
  get defaultLocale(): Locale {
    return this._defaultLocale;
  }

  /**
   * Gets application context
   */
  get context(): any {
    return this._context;
  }

  /**
   * Gets language equivalent for a key
   *
   * @param key - `string`
   * @param options
   * @returns `string`
   */
  t(
    key: Parameters<TFunction>[0],
    options?: Parameters<TFunction>[1],
  ): TFunctionResult {
    return this._t(key, options);
  }

  get Server(): Server {
    return this._server;
  }

  log(message: string): void {
    log(message);
  }
}
