import React, { PropsWithChildren, ReactElement } from "react";
import { Lesson_NewStudent } from "common/types";
import { HighlightedCourseLesson } from "../Lesson/HighlightedCourseLesson";
import { LessonCard } from "../Lesson/LessonCard";

import './CourseList.scss'

const IMAGE_MAPPER = {
  "spelling.story": "story",
  "student.videoplayer": "video",
  "spelling.cards": "definitions",
  "spelling.dropquiz": "drop-quiz",
  "spelling.fillblank": "fill-in-blank",
  "spelling.quiz": "multiple-choice",
  "spelling.proofreading": "proofreading",
  "spelling.crossword": "crossword",
  "spelling.dictation": "dictation",
  "spelling.shuffle": "jumble",
  "spelling.spellit": "spelling",
};

type Props = {
  lessons?: Lesson_NewStudent[];
};

export default function CourseList({
  lessons,
  ...other
}: PropsWithChildren<Props>): ReactElement<PropsWithChildren<Props>> {
  return (
    <>
    
      <div className='course-list-container'>
        <div className='course-list-grid'>
          {lessons?.map((lesson, index) => {
            const imageName =
              IMAGE_MAPPER[
                lesson?.activity?.app as keyof typeof IMAGE_MAPPER
              ] || "story";

            return lesson.is_highlighted ? (
              <HighlightedCourseLesson
                counter={index + 1}
                lesson={{
                  ...lesson,
                  image: `/images/lesson_icons/${imageName}.svg`,
                }}
                key={lesson.name.replace(" ", "") + lesson.id}
              />
            ) : (
              <LessonCard
                key={lesson.name.replace(" ", "") + lesson.id}
                image={`/images/lesson_icons/${imageName}.svg`}
                counter={index + 1}
                isBlocked={lesson.is_locked}
                isDone={lesson.objective_completion}
                name={lesson.name}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
