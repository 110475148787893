import React from "react";

export const ChevronLeft: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 9 9'
    className={`icon ${className}`}
  >
    <path d='M3.761,4.5l2.8-2.793A.811.811,0,1,0,5.412.56L2.044,3.925a.809.809,0,0,0-.015,1.127L5.41,8.441A.811.811,0,0,0,6.558,7.294Z' />
  </svg>
);
