import React from "react";

export const Outline: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    className={`icon ${className}`}
  >
    <path
      className='cls-1'
      d='M13,15H3A2.5,2.5,0,0,1,.5,12.5v-9A2.5,2.5,0,0,1,3,1H13a2.5,2.5,0,0,1,2.5,2.5v9A2.5,2.5,0,0,1,13,15ZM3,2A1.5,1.5,0,0,0,1.5,3.5v9A1.5,1.5,0,0,0,3,14H13a1.5,1.5,0,0,0,1.5-1.5v-9A1.5,1.5,0,0,0,13,2Z'
    />
    <path
      className='cls-1'
      d='M4.541,4.359a.736.736,0,1,0,.736.736.735.735,0,0,0-.736-.736Z'
    />
    <path
      className='cls-1'
      d='M11.732,5.6H6.941a.5.5,0,0,1,0-1h4.791a.5.5,0,0,1,0,1Z'
    />
    <path
      className='cls-1'
      d='M4.541,7.323a.736.736,0,1,0,.736.735.735.735,0,0,0-.736-.735Z'
    />
    <path
      className='cls-1'
      d='M11.732,8.592H6.941a.5.5,0,0,1,0-1h4.791a.5.5,0,0,1,0,1Z'
    />
    <path
      className='cls-1'
      d='M4.541,10.286a.736.736,0,1,0,.736.736.736.736,0,0,0-.736-.736Z'
    />
    <path
      className='cls-1'
      d='M11.732,11.589H6.941a.5.5,0,0,1,0-1h4.791a.5.5,0,0,1,0,1Z'
    />
  </svg>
);
