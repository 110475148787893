import { Children, ReactNode } from 'react';
import { getPropertyValue } from '../utils';

export function getGridTemplate(
  children: ReactNode,
  property: 'width' | 'height',
): string {
  let gridTemplate = '';
  Children.map(children, (child) => {
    const childProps = (child as React.ReactElement).props;
    let value = property === 'width' ? '1fr' : 'auto';
    if (childProps[property]) {
      value = childProps[property];
    } else if (childProps.fixedSize) {
      value = 'auto';
    }
    gridTemplate += `${getPropertyValue(value)} `;
  });

  return gridTemplate;
}
