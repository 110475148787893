import React from "react";

export const PlacementTest: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    className={`icon ${className}`}
  >
    <path
      className='cls-2'
      d='M10.81,15.583H3.19a.5.5,0,1,1,0-1h7.62a.5.5,0,0,1,0,1Z'
    />
    <path
      className='cls-2'
      d='M7,13.831a.5.5,0,0,1-.409-.214L5.029,11.39a5.713,5.713,0,1,1,3.932,0L7.405,13.617A.5.5,0,0,1,7,13.831Zm0-12.5A4.7,4.7,0,0,0,5.5,10.5a.5.5,0,0,1,.253.188L7,12.458l1.24-1.774a.5.5,0,0,1,.253-.188A4.7,4.7,0,0,0,7,1.331Z'
    />
    <path
      className='cls-2'
      d='M7,8.866A2.87,2.87,0,1,1,9.869,6,2.873,2.873,0,0,1,7,8.866ZM7,4.127A1.87,1.87,0,1,0,8.869,6,1.871,1.871,0,0,0,7,4.127Z'
    />
  </svg>
);
