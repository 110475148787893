import React from 'react';
import { Card } from 'ui/atoms/Card';
import { Image, Heading, Text } from 'ui/atoms';

import './LessonCard.scss';
import { Divider } from 'ui/atoms/Divider';
import { CheckmarkCircle, Lock } from 'ui/icons';

interface Props {
  image: string;
  name?: string;
  counter: number;
  isDone?: boolean;
  isBlocked?: boolean;
}

export const LessonCard: React.FC<Props> = ({
  image,
  counter,
  isBlocked,
  isDone,
  name,
}) => {
  return (
    <Card className='lesson-card'>
      <div className={`lesson-card-container ${isBlocked ? 'blocked' : ''}`}>
        <div className='header-wrapper'>
          <Heading variant='h5'>{counter.toString().padStart(2, '0')}</Heading>
          {isBlocked && <Lock className='lock-icon' />}
        </div>
        <Image src={image} className='lesson-image' />
        <div className='bottom-container'>
          <Divider orientation='horizontal' />

          {isDone && (
            <div className='done-icon-wrapper'>
              <CheckmarkCircle className='done-lesson-icon' />
            </div>
          )}
          <div className='name-container'>
            <Text>{name}</Text>
          </div>
        </div>
      </div>
    </Card>
  );
};
