import React from "react";
import { Heading, Text, Link } from "ui/atoms";
import { LessonCard } from "../LessonCard";
import { CourseLessonProps } from "../../types";

import "./HighlightedCourseLesson.scss";
import { Caret } from "ui/icons";

export const HighlightedCourseLesson: React.FC<CourseLessonProps> = ({
  lesson,
  counter,
}) => {
  return (
    <div className='highlighted-lesson'>
      <div className="highlighted-lesson-background"/>
      <LessonCard
        image={lesson.image}
        counter={counter}
        isBlocked={lesson.is_locked}
        isDone={lesson.objective_completion}
      />
      <div className='text-container'>
        <Heading variant='h4' fontWeight='bold'>
          {lesson.name}
        </Heading>
        <Text>{lesson.description}</Text>
        <Link href="#">
          Watch the video <Caret />
        </Link>
      </div>
    </div>
  );
};
