export const Scoreboard: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    className={`icon ${className}`}
  >
    <defs>
      <style>{`.scoreboard-cls-2,.scroreboard-cls-3{fill:currentColor;}.scroreboard-cls-3{isolation:isolate;font-size:6.67px;font-family:OpenSans-Bold, Open Sans;font-weight:700;}`}</style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <rect className='cls-1' width='16' height='16' fill="none"/>
        <path
          className='scoreboard-cls-2'
          d='M13.094,14.312H2.906A1.908,1.908,0,0,1,1,12.406V4.217A1.908,1.908,0,0,1,2.906,2.311H13.094A1.908,1.908,0,0,1,15,4.217v8.189A1.908,1.908,0,0,1,13.094,14.312ZM2.906,3.264a.954.954,0,0,0-.953.953v8.189a.954.954,0,0,0,.953.953H13.094a.955.955,0,0,0,.953-.953V4.217a.955.955,0,0,0-.953-.953Z'
        />
        <g id='Path_12820' data-name='Path 12820'>
          <rect
            className='scoreboard-cls-2'
            x='7.524'
            y='3.023'
            width='0.953'
            height='10.673'
          />
        </g>
        <g id='Path_12821' data-name='Path 12821'>
          <rect
            className='scoreboard-cls-2'
            x='10.663'
            y='0.9'
            width='0.953'
            height='2.123'
          />
        </g>
        <g id='Path_12822' data-name='Path 12822'>
          <rect
            className='scoreboard-cls-2'
            x='4.384'
            y='0.9'
            width='0.953'
            height='2.123'
          />
        </g>
        <text className='scroreboard-cls-3' transform='translate(2.7 10.592)'>
          0
        </text>
        <text className='scroreboard-cls-3' transform='translate(9.43 10.592)'>
          1
        </text>
      </g>
    </g>
  </svg>
);
