import React, { forwardRef, PropsWithRef, ReactElement, Ref } from 'react';
import { BaseProps, EventProps, MarginProps, PaddingProps } from '../../types';
import { buildStyle } from '../../utils';
import './Image.scss';

type Props = BaseProps &
  MarginProps &
  PaddingProps &
  EventProps<HTMLImageElement> & {
    src?: string;
    alt?: string;
  };

type ComponentProps = PropsWithRef<Props>;
type ComponentElement = ReactElement<ComponentProps>;

function Image({
  src,
  alt,
  className,
  ...other
}: ComponentProps, ref: Ref<HTMLImageElement>): ComponentElement {
  
  const style = buildStyle({ ...other });

  return <img className={`image ${className || ''}`} src={src} alt={alt} style={style} {...other} />;
}

/**
 * Image is used to insert and display an image within a component.
 */
const _Component = forwardRef(Image);
export {_Component as Image};