import React from "react";

export const Stats: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={`icon ${className}`}
    viewBox='0 0 16 16'
  >
    <path
      className='cls-1'
      d='M13.5,1.881v10h-3v-8.5a1.5,1.5,0,0,1,1.5-1.5h1.5m0-1H12a2.5,2.5,0,0,0-2.5,2.5v8.5a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1v-10a1,1,0,0,0-1-1Z'
    />
    <path
      className='cls-1'
      d='M9.5,4.881v7h-3v-5.5A1.5,1.5,0,0,1,8,4.881H9.5m0-1H8a2.5,2.5,0,0,0-2.5,2.5v6.5h5v-8a1,1,0,0,0-1-1Z'
    />
    <path
      className='cls-1'
      d='M5.5,7.881v4h-3v-2.5A1.5,1.5,0,0,1,4,7.881H5.5m0-1H4a2.5,2.5,0,0,0-2.5,2.5v2.5a1,1,0,0,0,1,1h4v-5a1,1,0,0,0-1-1Z'
    />
    <path
      className='cls-1'
      d='M13.993,15.619H1.91a.5.5,0,0,1,0-1H13.993a.5.5,0,1,1,0,1Z'
    />
  </svg>
);
