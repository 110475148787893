import { Property } from 'csstype';
import { CSSProperties } from 'react';
import {
  BaseProps,
  MarginProps,
  Margins,
  PaddingProps,
  Paddings,
  TextProps,
} from './types';

/**
 * Converts input value to the proper CSS property value format
 */
export function getPropertyValue(
  input: string | number | undefined,
): string | undefined {
  if (!input) return undefined;
  // if we get a number, we just convert it to pixels
  return typeof input === 'number' ? `${input}px` : input;
}

type PropertyValue = string | number | undefined;

export function getStyleProperties<T extends Margins | Paddings>(
  property: 'margin' | 'padding',
  p: Property.Padding | Property.Margin | undefined,
  px: PropertyValue,
  py: PropertyValue,
  pt: PropertyValue,
  pl: PropertyValue,
  pb: PropertyValue,
  pr: PropertyValue,
): T {
  const properties: any = {} as T;
  if (p) {
    properties[property] = getPropertyValue(p);
  } else {
    properties[`${property}Top`] = getPropertyValue(pt || py);
    properties[`${property}Right`] = getPropertyValue(pr || px);
    properties[`${property}Bottom`] = getPropertyValue(pb || py);
    properties[`${property}Left`] = getPropertyValue(pl || px);
  }

  return properties;
}

export function buildStyle({
  color,
  fontSize,
  fontWeight,
  lineHeight,
  align,
  verticalAlign,
  width,
  height,
  size,
  gap,
  m,
  mx,
  my,
  mt,
  ml,
  mb,
  mr,
  p,
  px,
  py,
  pt,
  pl,
  pb,
  pr,
}: BaseProps &
  MarginProps &
  PaddingProps &
  TextProps & { gap?: number | string }) {
  if (size) {
    width = height = size;
  }
  const style: CSSProperties = {
    color,
    fontSize: fontSize && getPropertyValue(fontSize),
    fontWeight,
    lineHeight: lineHeight && getPropertyValue(lineHeight),
    textAlign: align,
    verticalAlign,
    width: width && getPropertyValue(width),
    height: height && getPropertyValue(height),
    gap: gap && getPropertyValue(gap),
    ...getStyleProperties('margin', m, mx, my, mt, ml, mb, mr),
    ...getStyleProperties('padding', p, px, py, pt, pl, pb, pr),
  };

  return style;
}
