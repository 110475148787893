import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { Flex } from 'ui/layout';
import { Image, Text } from 'ui/atoms';
import { BaseProps } from 'ui/types';
import {Badge} from 'ui/atoms';
import './CourseHeader.scss';

type Props = BaseProps & {
  title: string | ReactNode;
  subTitle: string | ReactNode;
  state: HeaderState;
};

export type HeaderState = 'start' | 'maximized' | 'minimized';

let mainTitleWidth = 0;
let mainTitleHeight = 0;

const Menu: React.FC<{
  children?: React.ReactNode;
}> = ({
  children,
}) => {
  return (
    <div className={`menu`}>
      <Flex cols gap={5}>{children}</Flex>
      <Image src='/images/course_icons/caret.svg' size={8} pl={5}/>
    </div>
  );
}

export default function CourseHeader({
  title = '',
  subTitle = '',
  state: stateClass,
}: PropsWithChildren<Props>): ReactElement<PropsWithChildren<Props>> {

  const mainTitleRef = useRef<HTMLDivElement>(null);
  const subTitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function setSubTitlePosition(top: number, left: number, height: number): void {
      const element = subTitleRef.current!;
      element.style.top = top.toString() + 'px';
      element.style.left = left.toString() + 'px';
      element.style.height = height.toString() + 'px';
    }

    if (mainTitleRef.current) {
      const element = mainTitleRef.current;
      const width = element.clientWidth;
      const height = element.clientHeight;
      if (mainTitleWidth < width) {
        mainTitleWidth = width;
      }
      if (mainTitleHeight < height) {
        mainTitleHeight = height;
      }
      if(subTitleRef.current) {
        if (stateClass === 'start') {
          setSubTitlePosition(mainTitleHeight || height, -mainTitleWidth || -width, height);
        } else if (stateClass === 'maximized') {
          setSubTitlePosition(mainTitleHeight || height, -mainTitleWidth || -width, height);
        } else {
          setSubTitlePosition(-8, 5, height);
        }
      }
    }
  }, [stateClass, title, subTitle]);

  function getStateClass() {
    return stateClass === 'start' ? 'maximized' : `${stateClass}`;
  }

  return (
    <Flex cols className={`header header-${getStateClass()}`} pl={40} place={['flex-start','flex-start']}>
      <Flex place={['flex-start','center']} width='1fr' >
        <div className={`title-container`}>
          <div ref={mainTitleRef} className='main-title-container'>
            <div className={`animation title-bar title-bar-${getStateClass()}`}></div>
            <Text className={`animation main-title-${getStateClass()}`}>{title}</Text>
          </div>
          <div ref={subTitleRef} className={`${stateClass !== 'start' ? 'animation' : ''} sub-title-container`}>
            <Flex place={['flex-start', 'center']}>
              <Text className={`animation sub-title-${getStateClass()}`}>{subTitle}</Text>
            </Flex>
          </div>
        </div>
      </Flex>
      <Flex rows width='auto' pt={45} pr={35} gap={40}>
        <Flex cols gap={40} width='auto' place={['flex-end','center']}>
          <Menu>
            <Image src='/images/course_icons/language.svg' size={17}/>
            English
          </Menu>
          <Menu>
            <Image src='/images/course_icons/avatar.png' className='avatar' size={32}/>
            David M
          </Menu>
        </Flex>
        <Flex cols gap={8} className={`animation ${stateClass === 'minimized' ? 'hidden' : 'visible'}`}>
          <Badge>
            <Text fontWeight='bold'>76</Text>
            <Text>Stars</Text>
          </Badge>
          <Badge>
            <Text fontWeight='bold'>20,865</Text>
            <Text>Score</Text>
          </Badge>
          <Badge>
            <Text fontWeight='bold'>45%</Text>
            <Text>Progress</Text>
          </Badge>
        </Flex>
      </Flex>
    </Flex>
  );
}
