import React, { forwardRef, PropsWithChildren, ReactElement, Ref } from 'react';
import { BaseProps, EventProps, MarginProps, PaddingProps } from '../../types';
import { buildStyle } from '../../utils';
import './Box.scss';

type Props = 
  BaseProps & 
  MarginProps & 
  PaddingProps & 
  EventProps<HTMLDivElement>

type ComponentProps = PropsWithChildren<Props>;
type ComponentElement = ReactElement<ComponentProps>;

function Box({
  className,
  children,
  ...other
}: ComponentProps, ref: Ref<HTMLDivElement>): ComponentElement {
  
  const style = buildStyle({ ...other });

  return (
    <div className={`box ${className || ''}`} style={style} ref={ref} {...other}>
      {children}
    </div>
  );
}

/**
 * Simple div container for arrangement elements in the space
 */
const _Component = forwardRef(Box);
export {_Component as default};