export const Caret: React.FC<{ className?: string }> = ({ className }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 7' className={`icon ${className}`}>
    <defs>
      <style>{`.caret-cls-1{fill:currentColor;}.caret-cls-2{fill:none;}`}</style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <path
          id='Path_12556'
          data-name='Path 12556'
          className='caret-cls-1'
          d='M3.5,2.112H6.52a.449.449,0,0,1,.429.246.418.418,0,0,1-.047.468c-.109.122-.225.239-.34.354L3.842,5.946a.429.429,0,0,1-.487.136.535.535,0,0,1-.179-.121Q1.661,4.426.15,2.888a.433.433,0,0,1-.084-.556.436.436,0,0,1,.4-.221H3.5Z'
        />
        <rect className='caret-cls-2' width='7' height='7' />
      </g>
    </g>
  </svg>
);
