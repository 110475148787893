import React, {
  ReactElement,
  PropsWithChildren,
  CSSProperties,
  Ref,
  forwardRef,
} from 'react';
import { buildStyle } from '../../utils';
import { getGridTemplate } from '../utils';
import {
  BaseProps,
  EventProps,
  MarginProps,
  PaddingProps,
} from '../../types';
import './Cols.scss';

type Props = BaseProps &
  MarginProps &
  PaddingProps &
  EventProps<HTMLDivElement> & {
    gap?: string | number;
  };

type ComponentProps = PropsWithChildren<Props>;
type ComponentElement = ReactElement<ComponentProps>;

function Cols(
  { className, children, ...other }: ComponentProps,
  ref: Ref<HTMLDivElement>,
): ComponentElement {
  const style: CSSProperties = {
    gridTemplateColumns: getGridTemplate(children, 'width'),
    ...buildStyle({ ...other }),
  };

  return (
    <div
      className={`cols ${className || ''}`}
      style={style}
      ref={ref}
      {...other}
    >
      {children}
    </div>
  );
}

/**
 * An array of components arranged horizontally
 */
const _Component = forwardRef(Cols);
export { _Component as default };
