import React, { forwardRef, PropsWithChildren, ReactElement, Ref } from 'react';
import { BaseProps, EventProps, MarginProps, PaddingProps } from '../../types';
import { buildStyle } from '../../utils';
import './ScrollBox.scss';

type Props = BaseProps & MarginProps & PaddingProps & EventProps<HTMLDivElement>;
type ComponentProps = PropsWithChildren<Props>;
type ScrollBoxElement = ReactElement<ComponentProps>;

function ScrollBox({
  className,
  children,
  ...other
}: ComponentProps, ref: Ref<HTMLDivElement>): ScrollBoxElement {

  const style = buildStyle({ ...other });

  return (
    <div className={`scroll-box ${className || ''}`} style={style} ref={ref} {...other}>
      {children}
    </div>
  );
}

/**
 * Container with scroll bar
 */
 const _Component = forwardRef(ScrollBox);
 export {_Component as default};