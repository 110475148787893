import React, { ReactElement } from "react";
import {
  Link as ReactLink,
  LinkProps as ReactLinkProps,
  To,
} from "react-router-dom";
import "./Link.scss";

type ComponentProps = Omit<ReactLinkProps, "to"> & { to?: To } & {
  href?: string;
};
type ComponentElement = ReactElement<ComponentProps>;

export function Link({
  to,
  replace,
  className,
  children,
  ...other
}: ComponentProps): ComponentElement {
  if (to) {
    return (
      <ReactLink
        to={to}
        replace={!!replace}
        className={`link ${className || ""}`}
        {...other}
      >
        {children}
      </ReactLink>
    );
  } else {
    return (
      <a className={`link ${className || ""}`} {...other}>
        {children}
      </a>
    );
  }
}
